<template>
    <div class="table-responsive">
      <b-sidebar
        id="sidebar-response-task"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        backdrop
        right
        no-header
        shadow
        lazy
        width="620px"
      >
        <sidebar-template :closeAction="closeSidebar" :title="selectedTask.title" :titleSlot="`<div class='d-flex'><h3 class='text-primary'>${selectedTask.title}</h3></div>${selectedTask.description}<p></p>`">
          <template #content>
           <response-task-details :assessmentId="selectedTask.reference_id.assessment_id" :questionnaireId="selectedTask.reference_id.questionnaire_id" :responseId="selectedTask.reference_id.response_id" :taskId="selectedTask._id" :allowStatusChange="selectedTask.status===1" :status="selectedTask.status" :reloadParent="getAssignedTasksTm" :closeSidebar="closeSidebar"/>
          </template>
        </sidebar-template>

      </b-sidebar>
      <div class="col mt-2 py-1 rounded border">
            <b-row class="d-flex justify-content-between mb-2">
              <h4 class="mx-2 text-dark font-weight-bold">
                Filters
                <feather-icon class="ml-55" size="20" icon="FilterIcon" />
              </h4>
              <b-button
                variant="outline-danger"
                class="mx-2"
                size="sm"
                @click="resetFilters"
                ><feather-icon icon="XIcon" class="mr-50" />
                <span class="align-middle">Clear Filters</span></b-button
              >
            </b-row>
            <b-row>
              <div class="col-sm-12">
                <div
                  class="d-flex flex-wrap align-items justify-content w-60 "
                >
                  <!-- <b-form-group style="width:16%">
                    <v-select
                    :class="{
                        highlighted: highlightedFilter == 'vendor_id',
                      }"
                v-model="filters.vendor_id"
                label="text"
                :reduce="(opt) => opt.value"
                :options="vendorOptions"
                placeholder="--Select third Party--"
               
              /></b-form-group> -->
                  <!-- <b-form-group style="width: 20%">
                    <v-select
                      :class="{
                        highlighted: highlightedFilter == 'status',
                      }"
                      v-model="filters.status"
                      label="text"
                      :reduce="(opt) => opt.value"
                      :options="statusOptions"
                      placeholder="--Select Compliance Status--"
                    />
                  </b-form-group> -->
<!--   
                  <b-form-group style="width: 20%">
                    <v-select
                      :class="{
                        highlighted: highlightedFilter == 'evidence_status',
                      }"
                      v-model="filters.evidence_status"
                      label="text"
                      :reduce="(opt) => opt.value"
                      :options="evidenceStatusOptions"
                      placeholder="--Select Evidence Status--"
                    />
                  </b-form-group> -->
  
                  <b-form-group style="width: 15%" class="mr-1 mb-1">
                    <v-select
                    :class="{
                        highlighted: highlightedFilter == 'priority',
                      }"
                      v-model="filters.priority"
                      label="text"
                      :reduce="(opt) => opt.value"
                      :options="priorityOptions"
                      placeholder="--Select Priority--"
                    />
                  </b-form-group>
                  <b-form-group style="width: 15%" class="mr-1 mb-1">
                    <v-select
                    :class="{
                        highlighted: highlightedFilter == 'status',
                      }"
                      v-model="filters.status"
                      label="text"
                      :options="statusOptions"
                      placeholder="--Select Status--"
                      :reduce="opt=>opt.value"
                     
                    />
                  </b-form-group>
                </div>
              </div>
            </b-row>
            <b-row>
              <div class="app-fixed-search col-sm-12">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon
                      v-if="filters.title == null || filters.title == ''"
                      icon="SearchIcon"
                      class="text-muted"
                    />
                    <feather-icon
                      @click="
                        () => {
                          filters.title = null;
                        }
                      "
                      v-else
                      icon="XIcon"
                      class="text-danger cursor-pointer"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="filters.title"
                    placeholder="Search by title..."
                    debounce="500"
                    size="md"
                  />
                </b-input-group>
              </div>
            </b-row>
          </div>
      <table class="table table-hover table-condensed" hover responsive v-if="!loading" id="my-table" :per-page="perPage">
        <thead>
          <tr class="text-bold">
            <th>Name</th>
            <th class="text-center">Status</th>
            <th>Priority</th>
            <th>Created At</th>
            <th>Due Date</th>
            <!-- <th>Asset</th> -->
            <!-- <th>Assigned To</th> -->
          </tr>
        </thead>
  
        <tbody>
          <template v-if="Isloading === false">
            <template v-if="assignedtaskstm && assignedtaskstm.length > 0">
              <tr v-for="task in assignedtaskstm" :key="task._id" :class="{ completed: task.task_status == 2 }"
                @click="handleTaskClick(task)">
                <td style="max-width: 12vw; padding-right: 0" role="cell">
                  <p class="text-left font-weight-bold mb-0">{{ task.title }}</p>
                </td>
  
                <td role="cell" style="padding: 0">
                  <div class="d-flex justify-content-center">
                    <!-- <b-badge variant="primary" v-if="task.status == 0">Assigned</b-badge>
  
                    <b-badge variant="info" v-else-if="task.tatus == 4">Re-opened</b-badge> -->
  
                    <b-badge variant="warning" v-if="task.status == 1">Pending</b-badge>
  
                    <b-badge variant="success" v-else-if="task.status === 4">Approved</b-badge>
                    <b-badge variant="danger" v-else-if="task.status === 5">Rejected</b-badge>
                    <b-badge variant="danger" v-else-if="task.status === 6">Closed</b-badge>

  
                    <b-badge style="margin-right: 3px" variant="secondary" v-else>UnKnown</b-badge>
                  </div>
                </td>
  
                <td>
                  <div class="badge-wrapper">
                    <!-- Task Priority -->
  
                    <b-badge style="margin-right: 1px" variant="success" v-if="task.priority == 1">Low</b-badge>
  
                    <b-badge style="margin-right: 1px" variant="primary" v-else-if="task.priority == 2">Medium</b-badge>
  
                    <b-badge style="margin-right: 1px" variant="warning" v-else-if="task.priority == 3">High</b-badge>
  
                    <b-badge style="margin-right: 1px" variant="danger"
                      v-else-if="task.priority == 4">Critical</b-badge>
  
                    <b-badge variant="secondary" v-else>UnKnown</b-badge>
                  </div>
                </td>
  
                <td>
                  <span>{{ task.created_at |moment }}</span>
                </td>
                <td>
                  <span>{{ task.due_date | formatdate }}</span>
                </td>
                <!-- 
            <td>
              {{ task.asset_profile.asset_nickname }}
            </td> -->
  
                <!-- <td style="max-width: 8vw; padding-right: 0" role="cell">
                  <p class="mb-0 font-weight-bold" v-if="task.task_assigned_to">
                    {{ task.task_assigned_to.full_name }}
                  </p>
                </td> -->
                <!-- <small
              class="text-nowrap text-primary mr-1"
              v-if="task.task_assigned_to"
              ><b>{{ task.task_assigned_to.full_name }}</b>
            </small> -->
              </tr>
            </template>
            <template v-else>
              <empty-table-section title="Tasks Empty">
                <template #content>
                      <p class="font-weight-bold text-center">
                        It seems like there are no tasks at the moment.
                      </p>
                    </template>
  
  
              </empty-table-section>
            </template>
          </template>
          <tr style="height: 130px" class="bg-transparent" v-else>
            <td colspan="12">
              <template>
                <div class="w-auto d-flex flex-column align-items-center justify-content-center">
                  <b-spinner variant="primary" class="mr-1 text-center" />
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="demo-spacing-0 d-flex align-items-center justify-content-between my-1">
  <div class="w-100 d-flex justify-content-center">
    <b-pagination
      v-model="page"
      :total-rows="total"
      :per-page="10"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
    >
      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon" size="18" />
      </template>
      <template #next-text>
        <feather-icon icon="ChevronRightIcon" size="18" />
      </template>
    </b-pagination>
  </div>
</div>
    </div>
  </template>
  <script>
  import {
    BTable,
    BAvatar,
    BBadge,
    BButton,
    VBTooltip,
    BTabs,
    BTab,
    BSpinner,
    BSidebar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BPagination,
  } from "bootstrap-vue";
  import moment from "moment";
  import ResponseMixins from "../../../mixins/ResponseMixins";
  import EmptyTableSection from "../../../components/EmptyTableSection.vue";
  import ResponseTaskDetails from "../components/ResponseTaskDetails.vue";
  import SidebarTemplate from "@/components/SidebarTemplate.vue";
  import VSelect from "vue-select";
  export default {
    mixins: [ResponseMixins],
    components: {
      BTable,
      BAvatar,
      BBadge,
      BButton,
      VBTooltip,
      BTabs,
      BTab,
      moment,
      EmptyTableSection,
      BSpinner,
      BSidebar,
      ResponseTaskDetails,
      SidebarTemplate,
      BFormInput,
      BInputGroup,
      BInputGroupPrepend,
      VSelect,
      BPagination
    },
    data() {
      return {
        mytasks: [],
        assignedtasks: [],
        mytaskstm: [],
        assignedtaskstm: [],
        Isloading:false,
        selectedTask:{},
        filters:{
          title:null,
          risk:null,
          status:null,
          
        },
        page:1,
        total:null,
        statusOptions:[
          {
            text:'Pending',
            value:1
          },
          {
            text:'Approved',
            value:4
          },
          {
            text:'Rejected',
            value:5
          },
          {
            text:'Closed',
            value:6
          },
        ],
        priorityOptions:[
          {
            text:'Low',
            value:1
          },
          {
            text:'Medium',
            value:2
          },
          {
            text:'High',
            value:3
          },
          {
            text:'Critical',
            value:4
          },
        ]
      };
    },
    directives: {
      "b-tooltip": VBTooltip,
      highlight: {
        bind(el, binding) {
          if (binding.value) {
            el.classList.add("highlighted"); // Apply the highlight when the directive is bound
          }
        },
      },
    },

    filters: {
      moment: function (date) {
        return moment(date).format("D-MMM-YY h:mm");
      },
      formatdate: function (value) {
        if (value) {
          return moment(String(value)).format("D-MMM-YY");
        }
      },
    },
  
    async mounted() {
      console.log("Tm get Assigned tasks");
  
    
        this.getAssignedTasksTm(this.filters);
        if(this.$route.query.task_id){
          const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/tasks/${this.$route.query.task_id}`,
        };
       const res= await this.$http(options)
       this.selectedTask=res.data.data
       this.showSidebar()
        }
      
    },
    methods: {
      showSidebar(){
        this.$root.$emit("bv::toggle::collapse", "sidebar-response-task");
      },
      closeSidebar(){
        this.$root.$emit("bv::toggle::collapse", "sidebar-response-task");
      },
      resetFilters(){
        this.filters={
          title:null,
          risk:null,
          status:null,
        }
      },
      setAnimation(filter) {
        this.highlightedFilter = filter;
        setTimeout(() => {
          this.highlightedFilter = null;
        }, 1000);
      },
      handleTaskClick(task){
        if(task.status!==6){

          this.selectedTask=task
          this.showSidebar()
        }
      },

  
      gotoGrcTasks() {
        this.switchApp(process.env.VUE_APP_GRC_WEB_URL, "tasks");
      },
      getMytasksTm() {
        const options = {
          method: "GET",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            "org-id": this.$store.state.app.tenantId,
          },
          url: `${process.env.VUE_APP_TM_API_URL}task/task/my-tasks`,
        };
        this.$http(options)
          .then((resp) => {
            this.mytaskstm = resp.data.results;
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
          });
      },
      getAssignedTasksTm(params) {
        this.Isloading = true;
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/tpr/tasks`,
          params:{

            ...params,
            page:this.page
          }
        };
        this.$http(options)
          .then((resp) => {
            this.assignedtaskstm = resp.data.data.data;
            this.page=resp.data.data.current_page
            this.total=resp.data.data.total
            this.Isloading = false;
          })
          .catch((err) => {
            this.Isloading = false;
            console.log(err);
            this.handleError(err);
          });
      },
      getMyTasks() {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_CISO_API_URL}/tasks?assignee_id=${this.$store.state.app.user.user_id}&page=1&status=1&limit=5`,
        };
        this.$http(options)
          .then((resp) => {
            this.mytasks = resp.data.data.data;
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
          });
      },
      getAssignedTasks() {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_CISO_API_URL}/tasks?created_by=${this.$store.state.app.user.user_id}&page=1&status=1&limit=5`,
        };
        this.$http(options)
          .then((resp) => {
            this.assignedtasks = resp.data.data.data;
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
          });
      },
      getStatusIcon(value) {
        if (value && value !== null) {
          if (value == 1) {
            return "ClockIcon";
          } else if (value == 2) {
            return "TrendingUpIcon";
          } else if (value == 3) {
            return "EyeIcon";
          } else if (value == 4) {
            return "ThumbsUpIcon";
          } else if (value == 5) {
            return "SlashIcon";
          }
        }
      },
  
      getStatusText(value) {
        if (value && value !== null) {
          if (value == 1) {
            return "Pending";
          } else if (value == 2) {
            return "In Progress";
          } else if (value == 3) {
            return "In Review";
          } else if (value == 4) {
            return "Approved";
          } else if (value == 5) {
            return "Rejected";
          }
        }
      },
      getStatusNumber(num) {
        switch (num) {
          case 1:
            return "Pending";
          case 2:
            return "In Progress";
          case 3:
            return "In Review";
          case 4:
            return "Approved";
          case 5:
            return "Rejected";
          default:
            return "Pending";
        }
      },
      getStatusColor(num) {
        switch (num) {
          case 1:
            return "info";
          case 2:
            return "primary";
          case 3:
            return "warning";
          case 4:
            return "success";
          case 5:
            return "danger";
          default:
            return "info";
        }
      },
      getPriorityColor(num) {
        switch (num) {
          case 1:
            return "success";
          case 2:
            return "warning";
          case 3:
            return "danger";
          default:
            return "success";
        }
      },
      getPriorityNumber(num) {
        switch (num) {
          case 1:
            return "Low";
          case 2:
            return "Medium";
          case 3:
            return "High";
          case 4:
            return "Critical"
          default:
            return "Low";
        }
      },
    },
    watch:{

      filters:{
        handler(){
          this.page=1
          this.getAssignedTasksTm(this.filters)
        },
        deep:true
      },
      page(){
        this.getAssignedTasksTm(this.filters)
      }
    }
  };
  </script>
    <style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
    </style>
  <style lang="scss" scoped>
  .table-condensed>thead>tr>th,
  .table-condensed>tbody>tr>th,
  .table-condensed>tfoot>tr>th,
  .table-condensed>thead>tr>td,
  .table-condensed>tbody>tr>td,
  .table-condensed>tfoot>tr>td {
    padding: 0.8rem;
  }
  .filters_section {
    background-color: #f2f0f7;
  }
  
  .dark-layout {
    .filters_section {
      background-color: #161d31;
    }
  }
  </style>
    <style scoped>
    .highlighted {
      animation: breathingEffect 1s infinite ease-in-out; /* Apply breathing animation for 7 seconds */
    }
    
    @keyframes breathingEffect {
      0% {
        border: 1px solid #00cfe8;
        box-shadow: 0 0 10px 10px #00d1e883;
      }
      50% {
        border: 1px solid transparent;
        box-shadow: none;
      }
      100% {
        border: 1px solid #00cfe8;
        box-shadow: 0 0 10px 10px #00d1e883;
      }
    }
    </style>