<template>
    <div class="card p-1">
      <!-- <b-tabs>
       
        <b-tab title="Tasks from TM" lazy> -->
          <!-- <response-task-details/> -->
          <!-- <b-tabs justified>
            <b-tab title="My Tasks" lazy>
              <MyTasksTM />

            </b-tab>
            <b-tab title="Assigned Tasks" lazy> -->
              
              <div class="card-header pb-55">

                <div class="d-flex flex-column align-items-start justify-content-start">
                  <h3 class="mb-25 font-weight-bolder">Tasks</h3>
                  <p class="mb-0">Manage your Tasks.</p>
                </div>
              </div>

              <AssignedTasksTM/>

            <!-- </b-tab>
          </b-tabs> -->
        <!-- </b-tab>
      </b-tabs> -->
    </div>
  </template>
  <script>
  import {
    BTable,
    BAvatar,
    BBadge,
    BButton,
    VBTooltip,
    BTabs,
    BTab,
  } from "bootstrap-vue";
  import moment from "moment";
  import ResponseMixins from "../../mixins/ResponseMixins";

  import MyTasksTM from "./TM/MyTasksTM.vue";
  import AssignedTasksTM from "./TM/AssignedTasksTM.vue";
import ResponseTaskDetails from './components/ResponseTaskDetails.vue';
  export default {
    mixins: [ResponseMixins],
    components: {
      BTable,
      BAvatar,
      BBadge,
      BButton,
      VBTooltip,
      BTabs,
      BTab,
      moment,
      MyTasksTM,
      AssignedTasksTM,
      ResponseTaskDetails
    },
    data() {
      return {};
    },
    filters: {
      moment: function (date) {
        return moment(date).format("D-MMM-YY");
      },
      formatdate: function (value) {
        if (value) {
          return moment(String(value)).format("D-MMM-YY");
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .table-condensed > thead > tr > th,
  .table-condensed > tbody > tr > th,
  .table-condensed > tfoot > tr > th,
  .table-condensed > thead > tr > td,
  .table-condensed > tbody > tr > td,
  .table-condensed > tfoot > tr > td {
    padding: 0.8rem;
  }
  </style>