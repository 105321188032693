<template>
    <div class="table-responsive">
      <table class="table table-hover table-condensed" hover responsive v-if="!loading" id="my-table" :per-page="perPage">
        <thead role="rowgroup">
          <tr class="text-bold">
            <th>Name</th>
  
            <th class="text-center">Status</th>
  
            <th>Priority</th>
  
            <th>Created At</th>
  
            <!-- <th>Asset</th> -->
  
            <th>Assigned By</th>
          </tr>
        </thead>
  
        <tbody>
          <template v-if="Isloading === false">
            <template v-if="mytaskstm && mytaskstm.length > 0">
              <tr v-for="task in mytaskstm" :key="task.task_id" :class="{ completed: task.task_status == 2 }"
                @click="handleTMTaskClick('task/')">
                <td style="max-width: 12vw; padding-right: 0" role="cell">
                  <p class="text-left font-weight-bold mb-0">{{ task.task_title }}</p>
                </td>
  
                <td role="cell" style="padding: 0">
                  <div class="d-flex justify-content-center">
                    <b-badge variant="primary" v-if="task.task_status == 0">Assigned</b-badge>
  
                    <b-badge variant="info" v-else-if="task.task_status == 4">Re-opened</b-badge>
  
                    <b-badge variant="warning" v-else-if="task.task_status == 1">In Progress</b-badge>
  
                    <b-badge variant="success" v-else-if="task.task_status == 2">Completed</b-badge>
  
                    <b-badge style="margin-right: 3px" variant="secondary" v-else>UnKnown</b-badge>
                  </div>
                </td>
  
                <td>
                  <div class="badge-wrapper">
                    <!-- Task Priority -->
  
                    <b-badge style="margin-right: 1px" variant="primary" v-if="task.task_priority == 1">Low</b-badge>
  
                    <b-badge style="margin-right: 1px" variant="info" v-else-if="task.task_priority == 2">Medium</b-badge>
  
                    <b-badge style="margin-right: 1px" variant="warning" v-else-if="task.task_priority == 3">High</b-badge>
  
                    <b-badge style="margin-right: 1px" variant="danger"
                      v-else-if="task.task_priority == 4">Critical</b-badge>
  
                    <b-badge variant="secondary" v-else>UnKnown</b-badge>
                  </div>
                </td>
  
                <td>
                  <span>{{ task.created_at | formatdate }}</span>
                </td>
  
                <!-- <td>
              {{ task.asset_profile.asset_nickname }}
            </td> -->
  
                <td style="max-width: 8vw; padding-right: 0" role="cell">
                  <p class="mb-0 font-weight-bold" v-if="task.task_assigned_to">
                    {{ task.task_assigned_to.full_name }}
                  </p>
                  <!-- <small
                class="text-nowrap text-primary mr-1"
                v-if="task.task_assigned_to"
                ><b>{{ task.task_assigned_to.full_name }}</b>
              </small> -->
                </td>
              </tr>
            </template>
            <template v-else>
              <empty-table-section title="Tasks Empty">
                <template #content>
                      <p class="font-weight-bold text-center">
                        It seems like there are no tasks at the moment.
                      </p>
                    </template>
  
  
              </empty-table-section>
            </template>
          </template>
          <tr style="height: 130px" class="bg-transparent" v-else>
            <td colspan="12">
              <template>
                <div class="w-auto d-flex flex-column align-items-center justify-content-center">
                  <b-spinner variant="primary" class="mr-1 text-center" />
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  <script>
  import {
    BTable,
    BAvatar,
    BBadge,
    BButton,
    VBTooltip,
    BTabs,
    BTab,
    BSpinner
  } from "bootstrap-vue";
  import moment from "moment";
  import ResponseMixins from "../../../mixins/ResponseMixins";
  import EmptyTableSection from "../../../components/EmptyTableSection.vue";
  export default {
    mixins: [ResponseMixins],
    components: {
      BTable,
      BAvatar,
      BBadge,
      BButton,
      VBTooltip,
      BTabs,
      BTab,
      moment,
      EmptyTableSection,
      BSpinner
    },
    data() {
      return {
        mytasks: [],
        assignedtasks: [],
        mytaskstm: [],
        assignedtaskstm: [],
        Isloading: false
      };
    },
    filters: {
      moment: function (date) {
        return moment(date).format("D-MMM-YY");
      },
      formatdate: function (value) {
        if (value) {
          return moment(String(value)).format("D-MMM-YY");
        }
      },
    },
  
    mounted() {
      console.log("TM GET MY TASKS");
  
      if (
        this.$store.state.app.currentWorkspaceModules.includes(
          "Threat Management"
        )
      ) {
        this.getMytasksTm();
      }
    },
    methods: {
      gotoMyTask(id) {
        this.switchApp(process.env.VUE_APP_GRC_WEB_URL, `tasks?task_id=${id}`);
      },
  
      handleTMTaskClick(route) {
        this.$cookies.set(
          "redirectTMPath",
          route,
          null,
          null,
          process.env.VUE_APP_DOMAIN
        );
        const url = `${process.env.VUE_APP_TM_WEB_URL}${route}`;
        window.open(url, "_blank");
      },
  
      switchApp(appUrl, route) {
        this.$cookies.set(
          "redirectTMPath",
          route,
          null,
          null,
          process.env.VUE_APP_DOMAIN
        );
        const url = `${appUrl}${route}`;
        window.open(url, "_blank");
      },
  
      gotoGrcTasks() {
        this.switchApp(process.env.VUE_APP_GRC_WEB_URL, "tasks");
      },
      getMytasksTm() {
        this.Isloading = true;
        const options = {
          method: "GET",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            "org-id": this.$store.state.app.tenantId,
          },
          url: `${process.env.VUE_APP_TM_API_URL}task/task/my-tasks`,
        };
        this.$http(options)
          .then((resp) => {
            this.mytaskstm = resp.data.results;
            this.Isloading = false;
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
          });
      },
      getAssignedTasksTm() {
        const options = {
          method: "GET",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            "org-id": this.$store.state.app.tenantId,
          },
          url: `${process.env.VUE_APP_TM_API_URL}task/task/my-tasks-created`,
        };
        this.$http(options)
          .then((resp) => {
            this.assignedtaskstm = resp.data.results;
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
          });
      },
      getMyTasks() {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_CISO_API_URL}/tasks?assignee_id=${this.$store.state.app.user.user_id}&page=1&status=1&limit=5`,
        };
        this.$http(options)
          .then((resp) => {
            this.mytasks = resp.data.data.data;
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
          });
      },
      getAssignedTasks() {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_CISO_API_URL}/tasks?created_by=${this.$store.state.app.user.user_id}&page=1&status=1&limit=5`,
        };
        this.$http(options)
          .then((resp) => {
            this.assignedtasks = resp.data.data.data;
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
          });
      },
      getStatusIcon(value) {
        if (value && value !== null) {
          if (value == 1) {
            return "ClockIcon";
          } else if (value == 2) {
            return "TrendingUpIcon";
          } else if (value == 3) {
            return "EyeIcon";
          } else if (value == 4) {
            return "ThumbsUpIcon";
          } else if (value == 5) {
            return "SlashIcon";
          }
        }
      },
  
      getStatusText(value) {
        if (value && value !== null) {
          if (value == 1) {
            return "Pending";
          } else if (value == 2) {
            return "In Progress";
          } else if (value == 3) {
            return "In Review";
          } else if (value == 4) {
            return "Approved";
          } else if (value == 5) {
            return "Rejected";
          }
        }
      },
      getStatusNumber(num) {
        switch (num) {
          case 1:
            return "Pending";
          case 2:
            return "In Progress";
          case 3:
            return "In Review";
          case 4:
            return "Approved";
          case 5:
            return "Rejected";
          default:
            return "Pending";
        }
      },
      getStatusColor(num) {
        switch (num) {
          case 1:
            return "info";
          case 2:
            return "primary";
          case 3:
            return "warning";
          case 4:
            return "success";
          case 5:
            return "danger";
          default:
            return "info";
        }
      },
      getPriorityColor(num) {
        switch (num) {
          case 1:
            return "success";
          case 2:
            return "warning";
          case 3:
            return "danger";
          default:
            return "success";
        }
      },
      getPriorityNumber(num) {
        switch (num) {
          case 1:
            return "Low";
          case 2:
            return "Medium";
          case 3:
            return "High";
          default:
            return "Low";
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .table-condensed>thead>tr>th,
  .table-condensed>tbody>tr>th,
  .table-condensed>tfoot>tr>th,
  .table-condensed>thead>tr>td,
  .table-condensed>tbody>tr>td,
  .table-condensed>tfoot>tr>td {
    padding: 0.8rem;
  }
  </style>