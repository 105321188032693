<template>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <div
        class="w-100 h-100 d-flex flex-column align-items-start justify-content-start"
        v-if="response && response != null"
      >
        <div class="d-flex flex-column align-items-center justify-content-start w-100">
          <div
            class="d-flex flex-column align-items-start justify-content-start mb-1 w-100"
          >
            <b-badge :variant="status===1?'warning':status===4?'success':status===5?'danger':'info'"  class="mb-25"
              ><span class="d-flex align-items-center justify-content-start">
                <span>{{ `${status===1?'Pending':status===4?'Approved':status===5?'Rejected':''}` }}</span>
                <feather-icon icon="HelpCircleIcon" class="ml-25" /> </span
            ></b-badge>
            <h5 class="mb-0">{{ response.title }}</h5>
            <p class="mb-0">{{ response.description }}</p>
          </div>
  
          <div
            class="w-100 d-flex align-items-center justify-content-around  border-bottom border-top py-1"
          >
            <div class="d-flex flex-column align-items-center justify-content-start">
              <b-badge variant="success" pill class="mb-50"
                ><span class="d-flex align-items-center justify-content-start">
                  <span>Response</span>
                  <feather-icon icon="InfoIcon" class="ml-25" /> </span
              ></b-badge>
              <h5 class="mb-0 font-weight-bolder text-dark">
                {{ getResponseValue(response.response) }}
              </h5>
            </div>
  
            <!-- <div class="d-flex flex-column align-items-center justify-content-start">
              <b-badge variant="primary" pill class="mb-50"
                ><span class="d-flex align-items-center justify-content-start">
                  <span>Compliance</span>
                  <feather-icon icon="InfoIcon" class="ml-25" /> </span
              ></b-badge>
              <h5 class="mb-0 font-weight-bolder text-dark">
                {{ getComplianceValue(response.status) }}
              </h5>
            </div> -->
          </div>
        </div>
  
        <!-- <div
          class="w-100 d-flex align-items-center justify-content-center my-2"
          v-if="assessmentStatus == 1 && disableActions === false"
        >
          <b-button v-if="response.response===1 "  class="mr-1" size="sm" variant="danger" id="apply-noncompliance-popover"
            ><feather-icon icon="XCircleIcon" class="mr-50" /><span class="align-middle"
              >Apply Non-compliance</span
            ></b-button
          >
          <b-button v-if="response.response===1 " id="remediation-popover" class="mr-1" size="sm" variant="outline-dark"
            ><feather-icon icon="InfoIcon" class="mr-50" /><span class="align-middle"
              >Request Remediation</span
            ></b-button
          >
        </div> -->

        <!-- <b-popover
          ref="popover"
          target="remediation-popover"
          triggers="click"
          :show.sync="popover1Show"
          placement="bottom"
          container="my-container"
          @show="onPopover1Show"
          custom-class="wide-popover"
        >
          <template v-slot:title>
            <div class="d-flex justify-content-between align-items-center">
              <span>Please provide a reason.</span>
            </div>
          </template>
  
          <validation-observer ref="request-remediation-form" #default="{ invalid }">
            <b-form @submit.prevent="handleRequestRemediationSubmit">
              <b-form-group>
                <validation-provider #default="{ errors }" name="Reason" rules="required">
                  <b-form-textarea
                    id="textarea"
                    v-model="remediation_reason"
                    rows="4"
                    max-rows="6"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
  
              <div class="w-100 d-flex align-items-center justify-content-end">
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="mr-50"
                  @click="onPopover1Close"
                >
                  Cancel
                </b-button>
                <b-button size="sm" type="submit" variant="success" :disabled="invalid">
                  <feather-icon icon="CheckIcon" class="mr-50" />
                  Submit
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-popover> -->
  
        <!-- <b-popover
          ref="popover"
          target="apply-noncompliance-popover"
          triggers="click"
          :show.sync="popover2Show"
          placement="bottom"
          container="my-container"
          @show="onPopover2Show"
          custom-class="wide-popover"
        >
          <template v-slot:title>
            <div class="d-flex justify-content-between align-items-center">
              <span>Please provide a reason.</span>
            </div>
          </template>
  
          <validation-observer ref="apply-noncompliance-form" #default="{ invalid }">
            <b-form @submit.prevent="handleApplyNoncomplianceSubmit">
              <b-form-group>
                <validation-provider #default="{ errors }" name="Reason" rules="required">
                  <b-form-textarea
                    id="textarea"
                    v-model="remediation_reason"
                    rows="4"
                    max-rows="6"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
  
              <div class="w-100 d-flex align-items-center justify-content-end">
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="mr-50"
                  @click="onPopover2Close"
                >
                  Cancel
                </b-button>
                <b-button size="sm" type="submit" variant="success" :disabled="invalid">
                  <feather-icon icon="CheckIcon" class="mr-50" />
                  Submit
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-popover> -->
        <b-popover
          ref="popover"
          target="approve-evidences-popover"
          triggers="manual"
          :show.sync="popover4Show"
          placement="bottom"
          container="my-container"
          @show="onPopover4Show"
          custom-class="wide-popover"
        >
          <template v-slot:title>
            <div class="d-flex justify-content-between align-items-center">
              <span>Please provide a reason.</span>
            </div>
          </template>
  
          <validation-observer ref="apply-noncompliance-form" #default="{ invalid }">
            <b-form @submit.prevent="handleEvidenceApproveClick">
              <b-form-group>
                <validation-provider #default="{ errors }" name="Reason" rules="required">
                  <b-form-textarea
                    id="textarea"
                    v-model="approve_reason"
                    rows="4"
                    max-rows="6"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
  
              <div class="w-100 d-flex align-items-center justify-content-end">
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="mr-50"
                  @click="onPopover4Close"
                >
                  Cancel
                </b-button>
                <b-button size="sm" type="submit" variant="success" :disabled="invalid">
                  <feather-icon icon="CheckIcon" class="mr-50" />
                  Submit
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-popover>
         <b-popover
          ref="popover"
          target="reject-evidences-popover"
          triggers="click"
          :show.sync="popover3Show"
          placement="bottom"
          container="my-container"
          @show="onPopover3Show"
          custom-class="wide-popover"
        >
          <template v-slot:title>
            <div class="d-flex justify-content-between align-items-center">
              <span>Please provide a reason.</span>
            </div>
          </template>
  
          <validation-observer ref="apply-noncompliance-form" #default="{ invalid }">
            <b-form @submit.prevent="handleEvidenceRejectClick">
              <b-form-group>
                <validation-provider #default="{ errors }" name="Reason" rules="required">
                  <b-form-textarea
                    id="textarea"
                    v-model="reject_reason"
                    rows="4"
                    max-rows="6"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
  
              <div class="w-100 d-flex align-items-center justify-content-end">
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="mr-50"
                  @click="onPopover3Close"
                >
                  Cancel
                </b-button>
                <b-button size="sm" type="submit" variant="success" :disabled="invalid">
                  <feather-icon icon="CheckIcon" class="mr-50" />
                  Submit
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-popover>
  
        <div
          class="d-flex flex-column align-items-start justify-content-start mb-2 mt-2"
          
        >
        <div class="d-flex">
        </div>
          <label class="font-weight-bolder" v-if="response.note && response.note != null">Justification:</label>
          <p class="mb-0" v-if="response.note && response.note != null">{{ response.note }}</p>
        </div>
  
        <div
          class="d-flex flex-column align-items-start justify-content-start mb-2 mt-2"
          v-if="response.observation && response.observation != null"
        >
          <label class="font-weight-bolder">Observation:</label>
          <p class="mb-0">{{ response.observation }}</p>
        </div>
  
        <div
          class="d-flex flex-column align-items-start justify-content-start mb-2 mt-2"
          v-if="response.recommendation && response.recommendation != null"
        >
          <label class="font-weight-bolder">Recommendation:</label>
          <p class="mb-0">{{ response.recommendation }}</p>
        </div>
  
        <div class="w-100 mt-1">
          <!-- <b-tabs content-class="mt-1" justified>
            <b-tab title="Evidences" active> -->
              <!-- <div
                v-if="response.evidence_status == 3 || response.evidence_status == 4"
                class="w-100 py-1 px-75 d-flex flex-column align-items-center justify-content-start"
                :class="{
                  'bg-light-danger': response.evidence_status == 3,
                  'bg-light-danger': response.evidence_status == 4,
                }"
              >
                <p class="mb-0 font-weight-bold">
                  The evidences have been
                  <span v-if="response.evidence_status == 3">closed</span
                  ><span v-else-if="response.evidence_status == 4">closed</span> 
                </p>
              </div> -->
              <div
                class="d-flex flex-column justify-content-start align-items-center w-100"
                style="max-height: 55vh; position: relative"
              >
                <table role="table" class="table table-bordered">
                  <thead role="rowgroup">
                    <tr role="row">
                      <th role="columnheader" scope="col" class="header">File</th>
                      <th role="columnheader" scope="col" class="header">Uploaded By</th>
                      <th role="columnheader" scope="col" class="header">Uploaded At</th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    <template
                      v-if="
                        response.downloadable_evidences &&
                        typeof response.downloadable_evidences != 'undefined' &&
                        response.downloadable_evidences != null &&
                        response.downloadable_evidences.length > 0
                      "
                    >
                      <tr
                        v-for="evidence in response.downloadable_evidences"
                        :key="evidence.file_id"
                        role="row"
                      >
                        <td
                          aria-colindex="1"
                          role="cell"
                          style="padding-right: 0; max-width: 240px"
                        >
                          <div class="d-flex justify-content-start w-100">
                             <b-badge
                              :key="evidence.file_id"
                              variant="light-primary"
                              style="position: relative"
                              class="p-0 px-25 d-flex flex-direction-column align-items-center justify-content-between cursor-pointer"
                              v-b-tooltip.hover
                              :title="'Click to download: ' + evidence.original_file_name"
                              @click="
                                handleDownloadButtonClick(
                                  evidence.original_file_name,
                                  evidence.file_id
                                )
                              "
                            >
                              <div
                                style="width: 27px"
                                class="h-100 d-flex align-items-center justify-content-center"
                              >
                                <feather-icon
                                  v-if="!downloadingList.includes(evidence.file_id)"
                                  icon="DownloadIcon"
                                  class="mr-75"
                                  size="27"
                                />
                                <b-spinner
                                  v-else
                                  class="ml-0 mr-75"
                                  variant="primary"
                                  small
                                />
                              </div>
  
                              <p class="text-wrap text-left mb-0">
                                {{ evidence.original_file_name }}
                              </p>
                            </b-badge> 
                          </div>
                        </td>
                        <td aria-colindex="2" role="cell">
                          <span
                            class="mb-0 font-weight-bold"
                            v-if="evidence.uploaded_user && evidence.uploaded_user != null"
                          >
                            {{ evidence.uploaded_user.firstname }}
                            {{ evidence.uploaded_user.lastname }}
                          </span>
                        </td>
                        <td aria-colindex="3" role="cell">
                          {{ evidence.uploaded_at | moment }}
                        </td>
                      </tr>
    
  
                    </template>
                    <template v-else>
                      <empty-table-section title="No Evidences">
                        <template #content>
                          <p class="font-weight-bold text-center">
                            It seems like there are no Evidences at the moment.
                          </p>
                        </template>
                      </empty-table-section>
                    </template>
                  </tbody>
                </table>
                </div>
              <div
                v-if="allowStatusChange"
                class="w-100 d-flex flex-column align-items-center justify-content-start mt-0"
              >
                <div class="w-100 d-flex align-items-center justify-content-center">
                  <p class="mb-75 text-center">
                    Choose any of the following actions to
                    <span class="text-success font-weight-bold">Approve</span> or
                    <span class="text-danger font-weight-bold">Reject</span> the evidences
                    uploaded by the assigned users.
                  </p>
                </div>
  
                <div class="w-100 d-flex align-items-center justify-content-between">
                  <b-button
                    size="md"
                    block
                    variant="success"
                    class="m-0 mr-1"
                    @click="approveClick"
                    
                    
                    ><feather-icon icon="CheckCircleIcon" class="mr-50" /><span
                      class="align-middle"
                      id="approve-evidences-popover"
                      >Approve Evidences</span
                    ></b-button
                  >
                  <b-button
                    size="md"
                    variant="danger"
                    block
                    class="m-0 ml-1"
                    
                    id="reject-evidences-popover"
                    ><feather-icon icon="XCircleIcon" class="mr-50" /><span
                      class="align-middle"
                      >Reject</span
                    ></b-button
                  >
                </div>
              </div>
            <!-- </b-tab> -->
            <!-- <b-tab title="Discussions">
              <template v-slot:title>
                <div>
                  Discussions
                  <b-badge
                    v-if="comments.length > 0"
                    class="ml-25"
                    pill
                    variant="warning"
                    >{{ comments.length }}</b-badge
                  >
                </div>
              </template>
              <div class="w-100 h-auto">
                <comments-section
                  :comments="comments"
                  :showCommentLoading="showCommentLoading"
                  :disableCreation="disableActions"
                  :newCommentCallback="
                    (comment) => {
                      return addCommentAndSetData(
                        $route.params.assessmentId,
                        response.questionnaire_id,
                        response._id,
                        { comment }
                      );
                    }
                  "
                />
              </div>
            </b-tab>
            <b-tab title="Discussions">
              <template v-slot:title>
                <div>
                  reviews
                  <b-badge
                    v-if="response.tasked_users.length > 0"
                    class="ml-25"
                    pill
                    variant="warning"
                    >{{ response.tasked_users.length }}</b-badge
                  >
                </div>
              </template>
              <div class="w-100 h-auto">
                <reviews-section
                  :reviews="response.tasked_users"
                  :showCommentLoading="showCommentLoading"
                  :disableCreation="disableActions"
                  :newCommentCallback="
                    (comment) => {
                      return addCommentAndSetData(
                        $route.params.assessmentId,
                        response.questionnaire_id,
                        response._id,
                        { comment }
                      );
                    }
                  "
                />
              </div>
            </b-tab> -->
          <!-- </b-tabs> -->
        </div>
      </div>
    </b-overlay>
  </template>
  
<script>
import {
  BTooltip,
  BBadge,
  BSpinner,
  VBTooltip,
  BButton,
  BPopover,
  BFormTextarea,
  BFormRadioGroup,
  BFormGroup,
  BForm,
  BOverlay,
  BFormRadio,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import UtilsMixins from "../../../mixins/UtilsMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
import { required } from "@validations";
export default {
    data(){
        return {
            response:null,
            downloadingList:[],
            reject_reason:null,
            approve_reason:null,
            popover3Show:false,
            popover4Show:false
        }
    },
    filters: {
      moment: function (date) {
        return moment(date).format("D-MMM-YY h:mm");
      },
      formatdate: function (value) {
        if (value) {
          return moment(String(value)).format("D-MMM-YY");
        }
      },
    },
    props:{
        assessmentId:{
            type:String,
            required:true
        },
        questionnaireId:{
            type:String,
            required:true
        },
        responseId:{
            type:String,
            required:true
        },
        taskId:{
          type:String,
          required:true
        },
        allowStatusChange:{
          type:Boolean,
          default:false,
        },
        status:{
          type:Number,
          default:1
        },
        reloadParent: {
          type: Function,
          required: true,
        },
        closeSidebar:{
          type: Function,
          required: true,
        }

    },
    mixins:[UtilsMixins,ResponseMixins],
    components:{
      VBTooltip,
      BBadge,
      BSpinner,
      BButton,
      BPopover,
      BFormTextarea,
      BFormRadioGroup,
      BFormGroup,
      BForm,
      BOverlay,
      BFormRadio,
      BFormCheckboxGroup,
      BTooltip,
      EmptyTableSection,
      ValidationProvider,
      ValidationObserver,
    },
    mounted(){
        this.load()
    },
    methods:{
        load(){
            this.setQuestionData()
        },
        onPopover3Close(){
          this.popover3Show=false
        },
        onPopover4Close(){
          this.popover4Show=false
        },
        getResponseValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return "Not Given";
        }
      }
      return "Not Given";
    },

    getResponseVariant(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "success";
          case 2:
            return "warning";
          case 3:
            return "secondary";
          default:
            return "dark";
        }
      }
      return "dark";
    },

    getComplianceValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return "Unknown";
        }
      }
      return "Unknown";
    },
    approveClick(){
      this.$bvModal
        .msgBoxConfirm("Do you want to add a message?.", {
          title:"Add Approve message",
          size: "sm",
          okVariant: "success",
          okTitle: "Yes",
          cancelTitle: "Continue without message",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
          headerBgVariant: "light-warning",
        })
        .then((value) => {
          if (value===true) {
            this.popover4Show=true
            return
          }
          if(value===false){
            this.handleEvidenceApproveClick()
          }
        });
    },
        handleDownloadButtonClick(fileName, id) {
      if (!this.downloadingList.includes(id)) {
        this.downloadingList.push(id);
        const options = {
          method: "GET",
          responseType: "blob",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/file-uploads/${id}/download`,
        };
        this.debounceFunction(() => {
          this.$http(options)
            .then((res) => {
              var blob = new Blob([res.data]);
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, fileName);
              } else {
                var a = window.document.createElement("a");
                a.href = window.URL.createObjectURL(blob, {
                  type: "text/plain",
                });
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }

              this.downloadingList = this.downloadingList.filter(
                (item_id) => item_id !== id
              );
            })
            .catch((err) => {
              this.handleError(err);
              console.log(err);
            });
        });
      }
    },

        async handleEvidenceApproveClick(){
          const options={
            method:'PUT',
            url: `${process.env.VUE_APP_BASEURL}/tpr/tasks/${this.taskId}/status`,
            data:{status:4,approve_reason:this.approve_reason}
          }
          try {
            
            const res=await this.$http(options)
            this.handleResponse(res)
            this.reloadParent()
            this.closeSidebar()

          } catch (error) {
            this.handleError(error)
          }
          },
        async handleEvidenceRejectClick(){
          const options={
            method:'PUT',
            url: `${process.env.VUE_APP_BASEURL}/tpr/tasks/${this.taskId}/status`,
            data:{status:5,reject_reason:this.reject_reason}
          }
          try {
            
            const res=await this.$http(options)
            this.handleResponse(res)
            this.reloadParent()
            this.closeSidebar()
          } catch (error) {
            this.handleError(error)
          }
          },
        async setQuestionData(){
            const options = {
            method: "GET",
            url: `${process.env.VUE_APP_BASEURL}/tpr/assessments/${this.assessmentId}/questionnaires/${this.questionnaireId}/responses/${this.responseId}`,
            
            };
            const res=await this.$http(options)
            this.response=res.data.data
        }
    }
}
</script>